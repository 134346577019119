import React from "react";




const Header = () => {


return ( <header className="w-full text-gray-700 border-t border-gray-100 shadow-sm body-font">
<div className="container flex flex-col flex-wrap items-center p-5 mx-auto md:flex-row">

    <a
        className="flex items-center text-white order-first mb-4 font-medium text-gray-900 lg:order-none lg:w-1/5 title-font lg:items-center lg:justify-center md:mb-0">
        Federico Barenghi
    </a>
    <div className="inline-flex items-center h-full ml-5 lg:w-2/5 lg:justify-end lg:ml-0  p-4 rounded">

        <a href="#_" className="mr-5 font-medium hover:text-gray-900 hover:text-gray-400 hover:transition hover:delay-100">Home</a>
        <a href="#_" className="mr-5 font-medium hover:text-gray-900 hover:transition hover:delay-100">About</a>
        <a href="#_" className="font-medium hover:text-gray-900 hover:transition hover:delay-100">Contact</a>
   
    </div>
</div>
</header>)
}

export default Header;