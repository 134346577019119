import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import { Typewriter } from 'react-simple-typewriter'

function App() {

  


  
  return (
   <div className='flex flex-col gap-2 h-full md:h-screen w-full bg-[#0f172a]'>
  <Header />
  <div className="flex justify-center h-full">
  <div className="grid grid-cols-2 gap-9 text-white justify-items-center md:justify-items-start  text-white">

  <div className='p-8 col-span-2 md:col-span-1 md:text-start text-center'> 
  <div class="flex justify-center items-center  md:justify-start md:items-start "> 
  <img
  className="h-32 w-32 rounded object-cover object-center hidden"
  alt="Image placeholder"
  src={require("./assets/unnamed.jpg")}
/> 
</div> 
     <h1 className='font-extrabold text-5xl pt-5'>
      
      Welcome to Federico Barenghi's <p className='text-[#6366F1]'>Software Development Hub</p>
      
      </h1>

  <p className='pt-5'>
  Hi I'm Federico, a passionate software developer based in Milan. <br></br>
  My journey in the world of software development began in high school, where I discovered my love for creating innovative solutions through code.

After honing my skills and cultivating a deep understanding of various programming languages, algorithms, and software design principles, I embarked on a formal education journey at the Università degli Studi di Milano. This enriching experience not only solidified my technical foundation but also exposed me to a diverse range of challenges that fueled my curiosity and drive for continuous improvement.
</p>
  </div>
<div className='col-span-2 md:col-span-1 w-full z-50'>

</div>
</div>


  </div>
    <div className='flex fixed-bottom pb-10 justify-center text-white'>Copyright © 2024.</div>
   </div>
  );
}

export default App;
